// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$bb-white:				#fefefe;
$bb-black:				#242424;
$bb-gold:				#86744d;
$bb-gold-light:			#b4a479;
$bb-gold-lighter:		#ddcca9;
$bb-red:				#b31818;
$bb-green:				#97a256;

$navidi-gold-light:		#f7f4f3;
$navidi-gold:			#d5cfbd;

$brand-primary:         $bb-gold;

$input-border-radius:	0;
$input-bg:				lighten($bb-gold, 42%);

$font-family-brown:		'Brown-Regular', Georgia, sans-serif;
$font-family-base:      $font-family-brown !default;

//** Global text color on `<body>`.
$text-color:            $bb-black !default;

//** Global textual link color.
$link-color:            $bb-black !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($bb-black,7%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;