/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	vertical-align: top;
}

.fancybox-wrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8020;
}

.fancybox-skin {
	position: relative;
	background: #f2f2f0; background: rgba(242,242,240,0.95);
	color: #444;
	text-shadow: none;
//	-webkit-border-radius: 4px;
//	   -moz-border-radius: 4px;
//	        border-radius: 4px;
}

.fancybox-opened {
	z-index: 8030;
}

.fancybox-opened .fancybox-skin {
	-webkit-box-shadow: 0 10px 25px rgba(255, 255, 255, 0.7);
	   -moz-box-shadow: 0 10px 25px rgba(255, 255, 255, 0.7);
	        box-shadow: 0 10px 25px rgba(255, 255, 255, 0.7);
}

.fancybox-outer, .fancybox-inner {
	position: relative;
}

.fancybox-inner {
	overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
	-webkit-overflow-scrolling: touch;
}

.fancybox-error {
	color: #444;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
	padding: 15px;
	white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
	display: block;
	width: 100%;
	height: 100%;
}

.fancybox-image {
	max-width: 100%;
	max-height: 100%;
}

#fancybox-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -22px;
	margin-left: -22px;
	background-position: 0 -108px;
	opacity: 0.8;
	cursor: pointer;
	z-index: 8060;
}

#fancybox-loading div {
	width: 44px;
	height: 44px;
	background: url('/app/themes/bertolini/dist/images/fancybox/fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
	position: absolute;
	top: 10%;
	right: 10%;
	width: 50px;
	height: 50px;
	cursor: pointer;
	z-index: 8040;
	background: url('/app/themes/bertolini/dist/images/fancybox/close.svg') center center no-repeat;
}



.fancybox-nav {
	position: absolute;
	top: 0;
	width: 40%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
	background: transparent url('/app/themes/bertolini/dist/images/fancybox/blank.gif'); /* helps IE */
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	z-index: 8040;
}

.fancybox-prev {
	left: 0;
}

.fancybox-next {
	right: 0;
}

.fancybox-nav span {
	position: absolute;
	top: 50%;
	width: 40px;
	height: 80px;
	margin-top: -40px;
	cursor: pointer;
	z-index: 8040;
	visibility: hidden;
}

.fancybox-prev span {
	left: 20%;
    background: url('/app/themes/bertolini/dist/images/fancybox/pfeil-links.svg');
}

.fancybox-next span {
	right: 20%;
    background: url('/app/themes/bertolini/dist/images/fancybox/pfeil-rechts.svg');
}

.fancybox-nav:hover span {
	visibility: visible;
}

.fancybox-tmp {
	position: absolute;
	top: -99999px;
	left: -99999px;
	visibility: hidden;
	max-width: 99999px;
	max-height: 99999px;
	overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: hidden !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: none;
	z-index: 8010;
//	background: url('/app/themes/bertolini/dist/images/fancybox/fancybox_overlay.png');
    background: rgba(255,255,255, 0.95);
}

.fancybox-overlay-fixed {
	position: fixed;
	bottom: 0;
	right: 0;
}

.fancybox-lock .fancybox-overlay {
	overflow: auto;
	overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
	visibility: hidden;
	font-size: 13px;
	line-height: 20px;
	font-family: $font-family-base; letter-spacing: 0.03em;
	position: relative;
	text-shadow: none;
	z-index: 8050;
}

.fancybox-opened .fancybox-title {
	visibility: visible;
}

.fancybox-title-float-wrap {
	position: absolute;
	bottom: 0;
	right: 50%;
	margin-bottom: -35px;
	z-index: 8050;
	text-align: center;
}

.fancybox-title-float-wrap .child {
	display: inline-block;
	margin-right: -100%;
	padding: 2px 20px;
	background: #f2f2f0; background: rgba(242,242,240,0.92);
	-webkit-border-radius: 15px;
	   -moz-border-radius: 15px;
	        border-radius: 15px;
	color: $text-color;
	font-weight: 400;
	line-height: 24px;
	white-space: nowrap;
}

.fancybox-title-outside-wrap {
	position: relative;
	margin-top: 10px;
	color: $text-color;
}

.fancybox-title-inside-wrap {
	padding-top: 10px;
}

.fancybox-title-over-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #424242;
	padding: 10px;
	background: #000;
	background: rgba(0, 0, 0, .8);
}






/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	   only screen and (min--moz-device-pixel-ratio: 1.5),
	   only screen and (min-device-pixel-ratio: 1.5){

	#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
		background-image: url('/app/themes/bertolini/dist/images/fancybox/fancybox_sprite@2x.png');
		background-size: 44px 152px; /*The size of the normal image, half the size of the hi-res image*/
	}

	#fancybox-loading div {
		background-image: url('/app/themes/bertolini/dist/images/fancybox/fancybox_loading@2x.gif');
		background-size: 24px 24px; /*The size of the normal image, half the size of the hi-res image*/
	}
}

















/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
	padding: 0;
	margin: 0;
	border: 0;
	outline: none;
	vertical-align: top;
}

.fancybox-wrap {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8020;
}

.fancybox-skin {
	position: relative;
	background: #f2f2f0; background: rgba(242,242,240,0.92);
	color: #444;
	text-shadow: none;
//	-webkit-border-radius: 4px;
//	   -moz-border-radius: 4px;
//	        border-radius: 4px;
}

.fancybox-opened {
	z-index: 8030;
}

.fancybox-opened .fancybox-skin {
	-webkit-box-shadow: 0 10px 25px rgba(255, 255, 255, 0.7);
	   -moz-box-shadow: 0 10px 25px rgba(255, 255, 255, 0.7);
	        box-shadow: 0 10px 25px rgba(255, 255, 255, 0.7);
}

.fancybox-outer, .fancybox-inner {
	position: relative;
}

.fancybox-inner {
	overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
	-webkit-overflow-scrolling: touch;
}

.fancybox-error {
	color: #444;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
	padding: 15px;
	white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
	display: block;
	width: 100%;
	height: 100%;
}

.fancybox-image {
	max-width: 100%;
	max-height: 100%;
}

#fancybox-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -22px;
	margin-left: -22px;
	background-position: 0 -108px;
	opacity: 0.8;
	cursor: pointer;
	z-index: 8060;
}

#fancybox-loading div {
	width: 44px;
	height: 44px;
	background: url('/app/themes/bertolini/dist/images/fancybox/fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
	position: absolute;
	top: 7%;
	right: 7%;
	width: 50px;
	height: 50px;
	cursor: pointer;
	z-index: 8040;
	background: url('/app/themes/bertolini/dist/images/fancybox/close.svg') center center no-repeat;
}
@media (max-width: $screen-xs-max) {
  .fancybox-close {
    width: 25px; height: 25px; right: 4%; top: 4%;	
  }
}

.fancybox-nav {
	position: absolute;
	top: 0;
	width: 40%;
	height: 100%;
	cursor: pointer;
	text-decoration: none;
	background: transparent url('/app/themes/bertolini/dist/images/fancybox/blank.gif'); /* helps IE */
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	z-index: 8040;
}

.fancybox-prev {
	left: 0;
}

.fancybox-next {
	right: 0;
}

.fancybox-nav span {
	position: absolute;
	top: 50%;
	width: 40px;
	height: 80px;
	margin-top: -40px;
	cursor: pointer;
	z-index: 8040;
	visibility: hidden;
}
@media (max-width: $screen-xs-max) {
  .fancybox-nav span { width: 20px; height: 40px; margin-top: -20px; }
}
.fancybox-prev span {
	left: 10%;
    background: url('/app/themes/bertolini/dist/images/fancybox/pfeil-links.svg');
}
@media (max-width: $screen-xs-max) {
  .fancybox-prev span { left: 5%; }
  .fancybox-next span { right: 5%; }
}
.fancybox-next span {
	right: 10%;
    background: url('/app/themes/bertolini/dist/images/fancybox/pfeil-rechts.svg');
}

.fancybox-nav:hover span {
	visibility: visible;
}

.fancybox-tmp {
	position: absolute;
	top: -99999px;
	left: -99999px;
	visibility: hidden;
	max-width: 99999px;
	max-height: 99999px;
	overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: hidden !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	display: none;
	z-index: 8010;
//	background: url('/app/themes/bertolini/dist/images/fancybox/fancybox_overlay.png');
    background: rgba(255,255,255, 0.95);
}

.fancybox-overlay-fixed {
	position: fixed;
	bottom: 0;
	right: 0;
}

.fancybox-lock .fancybox-overlay {
	overflow: auto;
	overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
	visibility: hidden;
	font-size: 13px;
	line-height: 20px;
	font-family: $font-family-base; letter-spacing: 0.03em;
	position: relative;
	text-shadow: none;
	z-index: 8050;
}

@media (max-width: $screen-xs-max) {
  .fancybox-title { font-size: 11.5px; line-height: 16px; letter-spacing: 0; }
}

.fancybox-opened .fancybox-title {
	visibility: visible;
}

.fancybox-title-float-wrap {
	position: absolute;
	bottom: 0;
	right: 50%;
	margin-bottom: -35px;
	z-index: 8050;
	text-align: center;
}

.fancybox-title-float-wrap .child {
	display: inline-block;
	margin-right: -100%;
	padding: 2px 20px;
	background: #f2f2f0; background: rgba(242,242,240,0.92);
	-webkit-border-radius: 15px;
	   -moz-border-radius: 15px;
	        border-radius: 15px;
	color: $text-color;
	font-weight: 400;
	line-height: 24px;
	white-space: nowrap;
}

.fancybox-title-outside-wrap {
	position: relative;
	margin-top: 10px;
	color: $text-color;
}

.fancybox-title-inside-wrap {
	padding-top: 10px;
}

.fancybox-title-over-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	color: #fff;
	padding: 10px;
	background: #000;
	background: rgba(0, 0, 0, .8);
}












#fancybox-thumbs {
	position: fixed;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 8050;
}

#fancybox-thumbs.bottom {
	bottom: 2px;
}

#fancybox-thumbs.top {
	top: 2px;
}

#fancybox-thumbs ul {
	position: relative;
	list-style: none;
	margin: 0;
	padding: 0;
}

#fancybox-thumbs ul li {
	float: left;
//	padding: 1px;
	opacity: 0.5;
}

#fancybox-thumbs ul li.active {
	opacity: 0.75;
	padding: 0;
	border: none;
}

#fancybox-thumbs ul li:hover {
	opacity: 1;
}

#fancybox-thumbs ul li a {
	display: block;
	position: relative;
	overflow: hidden;
//	border: 1px solid transparent;
	background: #111;
	outline: none;
}

#fancybox-thumbs ul li img {
	display: block;
	position: relative;
	border: 0;
	padding: 0;
	max-width: none;
}