/* barbarabertolini.com */

// screenfonts
@font-face {
  font-family: 'Brown-Regular';
  font-style: normal; font-weight: 400;
  src: url('/app/themes/bertolini/dist/fonts/Brown-Regular.eot');
  src: url('/app/themes/bertolini/dist/fonts/Brown-Regular.woff') format('woff'), url('/app/themes/bertolini/dist/fonts/Brown-Regular.svg#Brown-Regular') format('svg');
}
@font-face {
  font-family: 'Brown-Bold';
  font-style: normal; font-weight: 600;
  src: url('/app/themes/bertolini/dist/fonts/Brown-Bold.eot');
  src: url('/app/themes/bertolini/dist/fonts/Brown-Bold.woff') format('woff'), url('/app/themes/bertolini/dist/fonts/Brown-Bold.svg#Brown-Bold') format('svg');
}



// general setup
html { height: 100%; background: transparent; }
body { text-rendering: optimizeLegibility; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;  position: relative; min-height: 100%; height: 100%; background: transparent;
//  border-top: 7px solid $bb-red;
}

// navigation
.nav-primary { opacity: 0.1;
  li { text-align: center;
	a { font-family: 'Brown-Regular', Georgia, sans-serif; font-weight: 400; }
  }
}

// navigation test
.overlay-bb {
  -webkit-transition: opacity 0.5s ease 0s, visibility 0s ease 0.5s; -moz-transition: opacity 0.5s ease 0s, visibility 0s ease 0.5s; -o-transition: opacity 0.5s ease 0s, visibility 0s ease 0.5s; transition: opacity 0.5s ease 0s, visibility 0s ease 0.5s;
  opacity: 0; visibility: hidden;
  ::-moz-selection { background: transparent; }
  ::selection { background: transparent; }
  nav > ul {
    -moz-transform: translateY(-25%) rotateX(35deg); -ms-transform: translateY(-25%) rotateX(35deg); -o-transform: translateY(-25%) rotateX(35deg); -webkit-transform: translateY(-25%) rotateX(35deg);
    transform: translateY(-25%) rotateX(35deg);
	-webkit-transition: transform 0.5s ease 0s, opacity 0.5s ease 0s; -moz-transition: transform 0.5s ease 0s, opacity 0.5s ease 0s; -o-transition: transform 0.5s ease 0s, opacity 0.5s ease 0s;
	transition: transform 0.5s ease 0s, opacity 0.5s ease 0s;
  }
}
.overlay-bb.open {
  -webkit-transition: opacity 0.5s ease 0s; -moz-transition: opacity 0.5s ease 0s; -o-transition: opacity 0.5s ease 0s; transition: opacity 0.5s ease 0s;
  opacity: 1; visibility: visible;
  nav > ul {
	-webkit-transform: rotateX(0deg); -moz-transform: rotateX(0deg); -o-transform: rotateX(0deg); transform: rotateX(0deg);
	opacity: 1;
  }
}
.overlay-bb.close {
  nav > ul {
	-webkit-transform: translateY(25%) rotateX(-27deg); -moz-transform: translateY(25%) rotateX(-27deg); -o-transform: translateY(25%) rotateX(-27deg); transform: translateY(25%) rotateX(-27deg);
  }
}
.overlay_menu {
	background-color: rgba(180, 164, 121, 0.90);
	height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
}
.main .overlay_menu nav {
    display: table;
    opacity: 1;
    float: none;
    font-size: 32px;
    height: 100%;
    position: absolute;
    text-align: center;
    width: 100% !important;
    width: 100%;
  li {
	 a, a:link, a:visited { text-decoration: none;
	 -webkit-transition: all 0.62s ease-in-out; -moz-transition: all 0.62s ease-in-out; -o-transition: all 0.62s ease-in-out; transition: all 0.62s ease-in-out; padding: 7px 15px;
    }
  }
  a:hover, a:active, a:focus { outline: 0;  background: transparent; color: lighten($bb-gold-lighter, 23%); text-shadow: 0 0 3px rgba(221,204,169,0.42);
	-webkit-transition: all 0.21s ease-in-out; -moz-transition: all 0.21s ease-in-out; -o-transition: all 0.21s ease-in-out; transition: all 0.21s ease-in-out;
  }
}

.overlay_menu ul {
    display: table-cell;
    float: none;
    list-style: outside none none;
    margin: 0 auto;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: 100%;
}
.menu-back { a { font-family: 'Brown-Bold', Georgia, sans-serif !important; font-weight: 600 !important; }
  &:after { content: ""; display: block; height: 6px; background: $bb-black; margin: 0.42em auto; width: 1.42em; }
}

// anchor links
.anchor:before { content: ''; display: block; position: relative; width: 0; height: 3em; margin-top: -3em }


// headers
h1, h2, h3, h4, .h1, .h2, .h3, .h4 { text-align: center; }
h2, .h2 { margin-bottom: 24px; }
h3.subtitle { font-size: 36px; margin-bottom: 42px; margin-top: 0;}
h3, .h3 { margin-bottom: 24px; margin-top: 42px; }
h1, h2, .h1, .h2 { font-family: 'Brown-Bold', Georgia, sans-serif; font-weight: 600; }
h4, .h4 { line-height: 24px; }
h5 { margin-bottom: 0; font-family: 'Brown-Bold', Georgia, sans-serif; font-weight: 600; }
h5.sub { margin-top: 24px; margin-bottom: 17px; }
h5.h3.sub { margin-top: 32px; margin-bottom: 24px; }
// regular content
.leftalign { text-align: left !important; }
.centered { text-align: center !important; }
hr.separator { border-top: 3px solid lighten($bb-gold, 13%); width: 4.2em; margin-top: 42px; margin-bottom: 36px; }
.home hr.separator { margin-bottom: 0; }
.main {
  hr { border-top: 3px solid lighten($bb-gold, 13%); width: 4.2em; margin-top: 42px; margin-bottom: 36px; }
  ul { margin-left: 17px; padding: 0; }
  ul.nodots { margin-left: 0; margin-bottom: 0;
	li { list-style: none; padding-left: 0; font-size: 18px; line-height: 32px; margin-bottom: 0 !important;
      @media (max-width: $screen-xs-max) {
	    font-size: 16px; line-height: 24px; margin-bottom: 10px !important;
      }
	}
  }
  p, li {
    a, a:link, a:visited { text-decoration: underline; }
    a:active, a:hover { text-decoration: none; }
  }
}
a.gold, a.gold:visited, a.gold:link { color: #fff; background: lighten($bb-gold, 7%); padding: 5px 4px 4px 4px; text-decoration: none !important;
  -moz-box-shadow: 0.3125em 0 0 lighten($bb-gold, 7%), -0.3125em 0 0 lighten($bb-gold, 7%);
  -webkit-box-shadow: 0.3125em 0 0 lighten($bb-gold, 7%), -0.3125em 0 0 lighten($bb-gold, 7%);
  box-shadow: 0.3125em 0 0 lighten($bb-gold, 7%), -0.3125em 0 0 lighten($bb-gold, 7%);
}
a.gold:hover, a.gold:active { text-decoration: underline; text-shadow: 0 0 1px rgba(255,255,255, .42);
  background: $bb-gold;
  -moz-box-shadow: 0.3125em 0 0 $bb-gold, -0.3125em 0 0 $bb-gold;
  -webkit-box-shadow: 0.3125em 0 0 $bb-gold, -0.3125em 0 0 $bb-gold;
  box-shadow: 0.3125em 0 0 $bb-gold, -0.3125em 0 0 $bb-gold;
}
.golden { color: $bb-gold; }
.uppercase { text-transform: uppercase; }

// responsive behaviour & tidbits
p { margin-bottom: 1.428571429em; }
p.nomargin { margin-bottom: 0; }
.relative { position: relative; }
.block { display: block; }
.line-height-1 { line-height: 1; }
.table { display: table; }
.m0 { margin: 0; }
.mt0 { margin-top: 0; }
.mt1 { margin-top: 1em; }
.mt2 { margin-top: 2em; }
.mt3 { margin-top: 3em; }
.mb1 { margin-bottom: 1em; }
.mb2 { margin-bottom: 2em; }
.mb3 { margin-bottom: 3em; }

.mb1 { margin-bottom: 0.5rem; }
.mb0 { margin-bottom: 0; }
.p1 { padding: 1rem; }
img { max-width: 100%; height: auto; }
.centered { text-align: center; }
@media (max-width: $screen-xs-max) {
  .centered-xs {
    text-align: center !important;
  }
}
.justified { text-align: justify; }
.noselect { -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

// modifier classes for fixed aspect ratios
.embed-responsive-10by3	{ padding-bottom: 30%; }
.embed-responsive-8by3 { padding-bottom: 37.5%; }
.embed-responsive-42 { padding-bottom: 42%; }
.embed-responsive-2by1 { padding-bottom: 50%; }
.embed-responsive-3by2 { padding-bottom: 66.667%; }
.embed-responsive-5by4 { padding-bottom: 80%; }
.embed-responsive-1by1 { padding-bottom: 100%; }
.embed-responsive-frame { padding-bottom: 91.6%; }
.embed-responsive-4by5 { padding-bottom: 125%; }
.embed-responsive-2by3 { padding-bottom: 150%; }

// vertical text
.holderL, .holderR { width: 10px; height: 10px; position: absolute; bottom: 50%; }
.holderR { right: 0; }
.holderT { position: absolute; text-align: center; top: -42px; width: 100%; }

.rotateL, .rotateR { width: 420px; height: 20px; position: absolute; top: -20px; text-align: center; }
.rotateL { left: -140px; -webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg); -ff-transform: rotate(-90deg); transform: rotate(-90deg); }
.rotateR { right: -140px; -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -ff-transform: rotate(90deg); transform: rotate(90deg); }

// Image Layouts
.flexgallery { z-index: 2; }
.gallerynote { width: 100%; color: $bb-gold; text-align: center; }

.leaf { margin: 10px; opacity: 0.01;
  -webkit-transition: all 0.62s ease-in-out; -moz-transition: all 0.62s ease-in-out; -o-transition: all 0.62s ease-in-out; transition: all 0.62s ease-in-out;

  a { display: inline-block; outline: none; }
  img { max-width: inherit; }
}
.tiled { opacity: 1 !important; }
.leaf-20-v { margin-top: 20px; margin-bottom: 20px; }
.leaf-30-v { margin-top: 30px; margin-bottom: 30px; }
.leaf-40-v { margin-top: 40px; margin-bottom: 40px; }
.leaf-50-v { margin-top: 50px; margin-bottom: 50px; }
.leaf-60-v { margin-top: 60px; margin-bottom: 60px; }
.leaf-70-v { margin-top: 70px; margin-bottom: 70px; }
.leaf-20-h { margin-left: 20px; margin-right: 20px; }
.leaf-30-h { margin-left: 30px; margin-right: 30px; }
.leaf-40-h { margin-left: 40px; margin-right: 40px; }
.leaf-50-h { margin-left: 50px; margin-right: 50px; }
.leaf-60-h { margin-left: 60px; margin-right: 60px; }
.leaf-70-h { margin-left: 70px; margin-right: 70px; }

@media (max-width: $screen-xs-max) {
  .row-xs { margin-left: -15px; margin-right: -15px; }
  .leaf { width: 49.5%; padding-left: 14px; padding-right: 14px; margin-top: 10px; margin-bottom: 10px; margin-left: 0; margin-right: 0;
	a { width: 100%; }
    img { margin: 0; width: 100%; }
  }
  .leaf-20-v, .leaf-30-v, .leaf-40-v, .leaf-50-v, .leaf-60-v, .leaf-70-v { margin-top: 10px; margin-bottom: 10px; }
  .leaf-20-h, .leaf-30-h, .leaf-40-h, .leaf-50-h, .leaf-60-h, .leaf-70-h { margin-left: 0; margin-right: 0; }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .leaf { margin: 7px;
    a {
	  img { }
    }
  }
  .leaf-20-v { margin-top: 14px; margin-bottom: 14px; }
  .leaf-30-v { margin-top: 21px; margin-bottom: 21px; }
  .leaf-40-v { margin-top: 28px; margin-bottom: 28px; }
  .leaf-50-v { margin-top: 35px; margin-bottom: 35px; }
  .leaf-60-v { margin-top: 42px; margin-bottom: 42px; }
  .leaf-70-v { margin-top: 49px; margin-bottom: 49px; }
  .leaf-20-h { margin-left: 14px; margin-right: 14px; }
  .leaf-30-h { margin-left: 21px; margin-right: 21px; }
  .leaf-40-h { margin-left: 28px; margin-right: 28px; }
  .leaf-50-h { margin-left: 35px; margin-right: 35px; }
  .leaf-60-h { margin-left: 42px; margin-right: 42px; }
  .leaf-70-h { margin-left: 49px; margin-right: 49px; }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .leaf { margin: 8px;
    a {
	  img { }
    }
  }
  .leaf-20-v { margin-top: 16px; margin-bottom: 16px; }
  .leaf-30-v { margin-top: 24px; margin-bottom: 24px; }
  .leaf-40-v { margin-top: 32px; margin-bottom: 32px; }
  .leaf-50-v { margin-top: 40px; margin-bottom: 40px; }
  .leaf-60-v { margin-top: 48px; margin-bottom: 48px; }
  .leaf-70-v { margin-top: 56px; margin-bottom: 56px; }
  .leaf-20-h { margin-left: 16px; margin-right: 16px; }
  .leaf-30-h { margin-left: 24px; margin-right: 24px; }
  .leaf-40-h { margin-left: 32px; margin-right: 32px; }
  .leaf-50-h { margin-left: 40px; margin-right: 40px; }
  .leaf-60-h { margin-left: 48px; margin-right: 48px; }
  .leaf-70-h { margin-left: 56px; margin-right: 56px; }
}


// hotelkontingente
.hotel {
  h4 { color: $bb-gold; }
  h3 { margin-top: 10px; margin-bottom: 10px; }
  &:after { content: ""; display: block; width: 3em; height: 2px; background: lighten($bb-gold, 17%); margin: 30px auto 24px auto; }
}
// ueber-mich
#ueber-mich {
  p { text-align: justify; }
}

// leistung
#leistung {
  p { text-align: justify; }
  ul { padding-left: 0; }
}
@media (max-width: $screen-xs-max) {
  #leistung ul { padding-left: 15px; }
}

// veranstaltungen
#konferenzen {
  p { text-align: justify; }
}

// artist
.segment.golden { background: $bb-gold; padding: 0 15px 0 15px !important; margin-bottom: 2.4em; }
#artist { padding-top: 2.1em; padding-bottom: 2.1em;
  color: #fff;
  @media (max-width: $screen-xs-max) {
    .portrait { margin-bottom: 1em; }
  }
  h2 { text-align: left; }
  p { text-align: justify; }
  a, a:link, a:visited, a:focus, a:hover { color: #fff; }
  .bluethnerlogo { width: 40%; }
}

@media (min-width: $screen-md-min) {
  .segment.waltrauthaas {
    background: $bb-gold url('../images/waltrauthaas.jpg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

// philosophie
#philosophie {
  p { text-align: justify; }
}

// award
#award { padding-top: 0; }

// partner
#partner { padding-top: 0;
  p { text-align: justify; }
}

// segments
.main { padding-top: 42px; padding-bottom: 42px; }
.segment { padding: 4.2em 0 4.2em 0;
  @media (max-width: $screen-xs-max) {
    padding: 2.4em 0 2.4em 0;
  }
}
.segment-white { background: #fff; z-index: 1; }

.sponsorlogo { width: 60%; height: auto; margin: 21px auto 21px auto; }

// Anmeldung
#anmeldung { padding-bottom: 3em !important; }

// Karin Keglevich-Lauringer
#special-pa { padding-bottom: 0;
  background: $navidi-gold-light;
  border-bottom: 3px solid $navidi-gold;
  #cut { margin-top: 2em; }
  p { text-align: justify; }
}

// Sandra Navidi
#sandranavidi { padding-bottom: 0;
  background: $navidi-gold-light;
  border-bottom: 3px solid $navidi-gold;
  #cut { margin-top: 2em; }
  p { text-align: justify; }
}

// Header Bertolini
#header-barbarabertolini { height: 50%; padding: 0; position: relative; backface-visibility: hidden; background-attachment: fixed;
  @media (orientation: landscape) {
	height: 70%;
  }
  @media (min-aspect-ratio: 16/9) {
	height: 90%;
  }
  #parallax-barbarabertolini { height: 150%; z-index: -1 !important; margin-left: auto; margin-right: auto; top: -75%; /* transform: translate3d(0,-75%,0); */ backface-visibility: hidden; background-attachment: fixed;
    img { backface-visibility: hidden; background-attachment: fixed; }
	@media (orientation: landscape) {
	  top: -90%;
    }
	@media (min-aspect-ratio: 16/9) {
	  top: -100%;
    }
  }
}

// Header Veranstaltungen
#header-veranstaltungen { height: 50%; padding: 0; position: relative;
  @media (orientation: landscape) {
	height: 70%;
  }
  @media (min-aspect-ratio: 16/9) {
	height: 90%;
  }
  #parallax-veranstaltungen { height: 140%; z-index: -1 !important; margin-left: auto; margin-right: auto; top: -40%;  }
}

// Header European Banking Congress
#header-europeanbankingcongress { height: 50%; padding: 0; position: relative;
  @media (orientation: landscape) {
	height: 70%;
  }
  @media (min-aspect-ratio: 16/9) {
	height: 90%;
  }
  #parallax-europeanbankingcongress { height: 140%; z-index: -1 !important; margin-left: auto; margin-right: auto;  top: -40%;  }
}





#bertolinilogo { width: 70%; display: inline-block; margin: 0 auto 42px auto; }
#barbarabertolini { width: 70%; display: inline-block; margin: 42px auto 90px auto; }
#impressum { display: none;
  background: lighten($bb-gold, 42%);
}

.video { display: block; background: #ddd; margin: 0 auto; width: 90%;
  img { border-bottom: 3px solid lighten($bb-gold, 13%); }
}
#zusammenfassung .video { width: 70%; margin: 42px auto 24px auto; }

.testimonials { margin-top: 14px; margin-bottom: -17px; }
.galerien { margin-top: 14px; margin-bottom: -17px;
  h3 { margin-top: 10px; margin-bottom: 30px; }
  .preview { width: 70%; margin: 0 auto; display: block; background: $bb-black;
	img {
	  opacity: 1;
	  -moz-transition: opacity 0.2s linear;
      -o-transition: opacity 0.2s linear;
      -webkit-transition: opacity 0.2s linear;
      transition: opacity .2s linear;
	}
	&:hover img { opacity: 0.7; }
  }
}
.single-galerie {
  a.brand { display: none; }
  h1, h2, h3, h4, .h1, .h2, .h3, .h4 { text-align: center; }
  h1.entry-title { margin-bottom: 1em; }
}

// events
.banner { display: none; }
.single-veranstaltung h1.entry-title  { margin: 42px auto 30px auto; }
#grussworte {

}
#ankuendigung {
  h3 + h2 { margin-top: 10px; }
}
#zusammenfassung {
  p { text-align: justify; }
}

.greetings {
  .img-circle { width: 42%; max-width: 210px; border: 3px solid lighten($bb-gold, 13%); margin-bottom: 13px; }
  .testimonial {
	p {  }
    h3, .h3, h4, .h4 { text-align: center; }
  }
  .testimonial:after { content: ""; display: block; width: 42%; height: 1px; background: lighten($bb-gold, 17%); margin: 24px auto 42px auto; }
}

.testimonial {
  p { text-align: justify; text-indent: 2em; margin-bottom: 0; }
  p:first-of-type { text-indent: 0 !important; }
  p:last-of-type { margin-bottom: 20px; }
  h3, .h3, h4, .h4 { text-align: left; }
  h3, .h3 { font-size: 16px; font-family: 'Brown-Bold', Georgia, sans-serif; font-weight: 600; line-height: 22px; margin-top: 0; margin-bottom: 2px; }
  h4, .h4 { font-size: 14px; line-height: 20px; font-family: 'Brown-Bold', Georgia, sans-serif; font-weight: 600; margin-top: 2px; margin-bottom: 10px; }
  hr {  border-top: 1px dotted $bb-gold; margin-top: 21px; margin-bottom: 21px;  }
}

.isotope { @include clearfix(); }

.presse {
  p { text-align: justify; }
}
.graphicrecording {
  p { text-align: justify; }
}
.single-downloadbereich .downloadbereich { margin-top: 42px;
  p { text-align: center; }
}
#gallery { margin-bottom: 21px; @include clearfix(); }


// Sprecher
.superbutton { background: lighten($bb-gold, 7%); border-radius: 0; text-decoration: none !important; font-weight: 600; border: 0; font-size: 20px;
    font-weight: 600; letter-spacing: -0.01em; margin-top: 2em; text-decoration: none !important;
}
.superbutton:hover { background: $bb-gold; text-shadow: 0 0 2px rgba(255,255,255,0.42); }
.single-sprecher {
  h1, h2, h3, h4, .h1, .h2, .h3, .h4 { text-align: left; }
  .list-inline { margin-left: 0;
	a, a:link, a:visited { font-weight: 600; text-decoration: none; }
	a:hover, a:active { text-decoration: underline; }
	li { padding-left: 0; padding-right: 0; color: $bb-gold; }
  }
  .portrait { margin-bottom: 20px; }
  .zitat { margin-top: 30px; margin-bottom: 30px;  padding-left: 0.42em; text-indent: -0.42em; margin-left: -0.42em; line-height: 28px; color: $bb-gold; letter-spacing: -0.024em;
	 @media (max-width: $screen-xs-max) { font-size: 20px; line-height: 24px; }
  }
  .main hr.job { margin: -10px auto 9px 0; border-top: 1px dotted $bb-gold; }
  .position { font-size: 14px; line-height: 20px; font-weight: 600; margin-top: 10px; margin-bottom: 20px; letter-spacing: -0.01em; }
  p { text-align: justify; }
  .backlink { margin-bottom: 14px;
	@media (min-width: $screen-sm-min) { margin-left: -0.842em; }
  }
}
#sprecher-intro {
  h2+h3 { margin-top: 0; }
}
#sprecher { margin-bottom: 21px; }
.sprecher { margin-top: 15px; margin-bottom: 15px;
  .item { padding: 5px; }
  .col-borderless {
	 @media (max-width: $screen-xs-max) { margin-left: -15px; margin-right: -15px; }
  }
  .relative { overflow: hidden; color: #fff; }
  .relative:hover .sprecher-info { transform: translate(0px, 0px); }
  .item {
    @media (max-width: $screen-xs-max) {
	  margin-bottom: 10px;
    }
  }
  img { width: 40%;  position: relative; }
  img:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  .sprecher-info { background: $bb-gold; left: 40%; position: absolute; padding-top: 40%; top: 0; width: 60%;
	h2, .h2, p { text-align: center !important; }
	.h5 { font-weight: 600; padding-bottom: 3px; }
  }
  @media (max-width: $screen-xs-max) {
	margin-top: 0;
    .h2 { font-size: 17px; line-height: 21px; }
    .h5, p { font-size: 13px; line-height: 13px; }
  }
  @media (min-width: $screen-sm-min) {
    img { width: 100%; }
    .sprecher-info { left: 0; padding-top: 100%; transform: translate(105%, 0px); transition: all 0.3s ease 0s; width: 101%; }
  }
  .copy { left: 0; position: absolute; top: 50%; transform: translateY(-50%); width: 100%; }
  .p1 { padding: 0rem; }
}

#programm {
  table { width: 100%; border-bottom: 1px solid $bb-gold; margin-bottom: 1em; }
  tr { vertical-align: top; border-top: 1px dotted $bb-gold; }
  td { padding-top: 7px; padding-bottom: 7px;
	p { margin-bottom: 10px; }
	p:last-child { margin-bottom: 0; }
	ul { padding: 0; margin-left: 0; margin-top: -5px; margin-bottom: 0; }
	li { list-style: none; }
  }
  tr:hover td { background: lighten($bb-gold, 50%); color: black; }
  td.time { width: 15%; text-align: left; padding-right: 2em; }
  td.type { width: 25%;
	@media (max-width: $screen-sm-max) {
      display: none;
    }
  }
  td.description { width: 60%; }
}

// Passwort
.single-downloadbereich .ginput_container {
  label { margin-left: 0; }
  input { display: inline-block; }
  input[type="password"] { display: inline-block; }
    input[type="submit"] { background: $bb-green; border-color: $bb-green; border-radius: 0; margin-left: 10px; margin-top: -2px;
    &:hover, &:visited { background: darken($bb-green,4.2%); border-color: darken($bb-green,4.2%); color: #fff; }

  	color: $bb-white;
    -moz-user-select: none;
    background-image: none;
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857;
    margin-bottom: 0;
    padding: 6px 12px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
  }
}

// Wappen
#wappen { padding-bottom: 2.1em;
  background: $bb-gold-light; color: $bb-white;
  a.maillink { display: block; padding-bottom: 13px; opacity: 0.02;
	-webkit-transition: all 0.42s ease-in-out; -moz-transition: all 0.42s ease-in-out; -o-transition: all 0.42s ease-in-out; transition: all 0.42s ease-in-out;
	&:hover { transform: scale(1.0242); }
  }
  a, a:link, a:visited { color: $bb-white; }
  a:hover, a:focus { color: #fff; text-shadow: 0 0 1px rgba(255,255,255,0.42); }
  small { color: $bb-gold-lighter; -webkit-transition: all 0.42s ease-in-out; -moz-transition: all 0.42s ease-in-out; -o-transition: all 0.42s ease-in-out; transition: all 0.42s ease-in-out;
	&:hover { color: $bb-white; }
  }
  &:hover small { color: $bb-white; }
}

// Forms
label { margin-left: 1px; }
.ginput_complex label, .gfield_description { color: $bb-black; font-size: 14px; margin: 3px 0 17px 1px !important; }
.single-downloadbereich input[type=text],
.ginput_container input[type=text],
.ginput_container input[type=email],
.ginput_container input[type=number],
.ginput_container input[type=url],
.ginput_container input[type=tel],
.ginput_container input[type=date],
.ginput_container input[type=password],
.ginput_container select,
.ginput_container textarea { border-radius: 0; &:focus { background: lighten($bb-gold, 50%); color: black; } }
.gform_wrapper .gfield_checkbox li input[type="checkbox"] { float: left; }
.gform_wrapper .gfield_checkbox li label, .gform_wrapper .gfield_radio li label {
    display: block;
    line-height: 1.5;
    margin: 0 0 0 24px;
    padding: 0;
    vertical-align: top;
    width: auto;
}
.gform_wrapper { margin-top: 42px;
  textarea.large { height: 9.42em; }
  .alert-danger, .validation_error { background: $bb-red; border-color: $bb-red; color: $bb-white; font-weight: 700; }
  ul { padding-left: 0; }
  .btn-primary, .comment-form input[type="submit"], .gform_button { background: $bb-green; border-color: $bb-green; border-radius: 0; margin-left: 17px;
    &:hover, &:visited { background: darken($bb-green,4.2%); border-color: darken($bb-green,4.2%); }
  }
  .adressfelder { margin-bottom: 0; }
  .trennelement {
	&:before { content: ""; display: block; width: 42%; width: 4.2em; height: 0.01em; border-top: 1px dotted $bb-gold; background: lighten($bb-gold, 17%); margin: 32px auto 42px auto;  }
	h2 { font-size: 20px; line-height: 24px; }
  }
  .bittedanke { margin-top: -32px; }
}

body#tinymce {
  height: auto;
  min-height: auto !important;
}

#tinymce {
  height: auto;
  min-height: auto !important;
}

.datenschutzerklaerung {
  .page-header {
    display: none;
  }
}

.smallprint {
  ol {
    counter-reset: item;
    padding-left: 0;

    li {
      display: block;
      font-size: 1.7rem;
      line-height: 1.2;
      font-weight: 400;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;

      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        text-align: right;
        display: inline-block;
        width: 3.5rem;
        position: absolute;
        left: -3rem;
      }

      ol {
        margin-top: 0.75rem;
        margin-bottom: 1.75rem;
        padding-left: 0;
        position: relative;
      }

      li {
        font-size: 1.4rem;
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 0.75rem;

        &::before {
          width: 4rem;
          left: -5rem;
        }
      }

      ul {
        list-style: outside none none;
        padding-left: 1rem;
        margin-top: 0.75rem;

        li {
          position: relative;
        }

        li::before {
          content: "+";
          left: -1.5rem;
          width: 1rem;
          display: inline-block;
          position: absolute;
        }
      }
    }
  }
}


//
//	Folkhack's Gravity Forms styling
//
//	Table of Contents:
//	- Variables/configuration
//	- General fixes
//	- Required *
//	- input, select, and textarea elemnt extends
//	- Button extend
//	- Error/notification messages handling
//	- Gravity Forms complex containers
//	- Weird-ass clear-multi shit
//	- Date fields
//	- Lists
//	- General Datepicker
//	- Folkhack's jQuery UI Datepicker: (DEPRECIATED!)
//  - Validation container
//
//
//	Based on:
//	- http://roots.io/style-gravity-forms-with-bootstrap/
//	- https://gist.github.com/Gavrisimo/8406309
//	- https://gist.github.com/spigists/6796258
//
//	TODO:
//	- Clean this up once Gravity Forms more-or-less gets their shit more together
//	- Rip apart provided Gravity Forms CSS and implement all specific feature-sets
//	- Better handling of date-fields, currently drop-down dates are AOK to use
//

//
//	Variables/configuration:
//

// Enables/disables jQuery UI datepicker (jQuery UI can go die in a fire...IMHO)
$fh_gf_jqueryui_datepicker_enabled: false !default;
// Setup a reasonable responsive break-point for complex fields
$fh_gf_responsive_break_at: $screen-sm-min !default;


//
//	General fixes:
//

// Field/form container styling:
.gform_wrapper ul { @extend .list-unstyled; }
.gform_wrapper li { @extend .form-group; }
.gform_wrapper form { margin-bottom: 0; }

// Field container clear
.gfield { clear: both; }

// Form description
.gform_heading .gform_description {
	display: block;
	margin: 0 0 10px 0;
}

// Radios/checks
.gfield_checkbox,
.gfield_radio {
	margin: 0 0 0 10px;
	label {
		font-weight: normal;
		margin-left: 10px;
	}
	li { margin-bottom: 0px; }
}
.gfield_checkbox { margin-left: 0; }

// Multiple select and textarea height fixes
.ginput_container select[multiple] { height: auto; }
.ginput_container textarea { height: auto; }
#field_2_9 { margin-bottom: 0; }

//
//	Required *:
//
.gform_wrapper .gfield_required {
	color: $state-danger-text;
	padding-left: 1px;
}


//
//	input, select, and textarea elemnt extends:
//
.ginput_container input[type=text],
.ginput_container input[type=email],
.ginput_container input[type=number],
.ginput_container input[type=url],
.ginput_container input[type=tel],
.ginput_container input[type=date],
.ginput_container input[type=password],
.ginput_container select,
.ginput_container textarea {
	// @extend .form-control;
  display: block;
  width: 100%;
  height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus;

  // Placeholder
  @include placeholder;

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }


  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }

	border: none;
	box-shadow: none;
}


//
//	Button extend:
//
.gform_button {
	@extend .btn;
	@extend .btn-primary;
}

//
//	Error/notification messages handling:
//
.gform_wrapper .gfield_error {
	.gfield_label { color: $state-danger-text; }
	input,
	select,
	textarea {
		background-color: $alert-danger-bg;
		border-color: $alert-danger-border;
		color: $alert-danger-text;
		@include form-control-focus( $alert-danger-text );
	}
}
.validation_error {
	@extend .alert;
	@extend .alert-danger;
}
.validation_message {
	margin-top: 5px;
}
#gforms_confirmation_message { @extend .alert; }

.gfield_description.validation_message { display: none; }
//
//	Gravity Forms complex containers:
//	- based on discussion from http://roots.io/style-gravity-forms-with-bootstrap/
//	- HELLA refactored to SCSS/responsive sanity =/
//
.ginput_complex {

	// Add a 10px gray border to the left side of complex groupings
	display: block;
	// padding: 10px 0 0 0;

	// Fix label font weight and top spacing
	label {
		font-weight: normal;
		margin: 5px 0 15px 0;
	}
	span:last-of-type label { margin: 5px 0 0 0; }

	// Full-width container
	.ginput_full { width: 100%; }

	// Left/right containers (ex: first/last name)
	.ginput_left,
	.ginput_right,
	.name_first,
	.name_last {
		float: left;
		width: 49%;
	}
	.ginput_left, .name_first { margin-right: 1%; }
	.ginput_right, .name_last { margin-left: 1%; }

    @media (max-width: $screen-xs-min) {
 	  .ginput_left,
	  .ginput_right,
	  .name_first,
	  .name_last {
		float: none;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	  }
	}

	// Inputs and labels
	.ginput_full {
		input,
		label {
			width: 100% !important;
		}
	}
	.ginput_left {
		input,
		select,
		textarea,
		label {
			width: 95% !important;
		}
	}

	// Responsive break for full-width input fields
	@media screen and ( max-width: $fh_gf_responsive_break_at ) {
		.ginput_full,
		.ginput_left,
		.ginput_right {
			float: none;
			width: 100% !important;
			input { width: 100% !important; }
			label { width: 100% !important; }
		}
	}
}

// Add BS3 clearfix
.gf_clear_complex { @extend .clearfix; }


//
//	Weird-ass clear-multi shit:
//
.clear-multi {

	display: table;

	// Add BS3 clearfix
	@extend .clearfix;

	// Time/date fields
	// - consider refactoring to be wrapped in .gfield_time_* and .gfield_date_*
	//   keeping non-wrapped due to compiled CSS bloat. Keep an eye on this!
	.ginput_container {
		display: table-cell;
		padding-left: 5px;
		input { width: 70px; }
		select { width: 80px; }
		// Disable the labels for time (yeahhh no need really)
		label { display: none; }
	}
	.ginput_container:first-child { padding-left: 0; }

	// This is hack-y but fixes a rendering bug on Chrome
	// TODO: clean this up
	.gfield_time_hour,
	.gfield_time_minute,
	.gfield_time_ampm {
		float: left;
	}

	// Inline block so that input element aligns properly with ":"
	.gfield_time_hour input { display: inline-block; }

	//
	//	Date fields
	//
	.gfield_date_month,
	.gfield_date_day,
	.gfield_date_year {
		// Re-enable the date field labels
		label {
			display: block;
			font-weight: normal;
		}
	}
}


//
//	Lists:
//
.gfield_list {
	// Offset the top padding for the cell spacing
	margin-top: -5px;
	tr .gfield_list_cell { padding: 5px 0; }
	.gfield_list_icons {
		vertical-align: middle !important;
		width: 60px;
		img { float: left; }
	}
}


//
//	General Datepicker:
//
.gfield .datepicker {
	display: inline-block !important;
	width: 170px !important;
}

//
//	Folkhack's jQuery UI Datepicker: (DEPRECIATED!)
//	- hide jQuery UI datepicker in lieu of using the HTML type="date" option
//	- left this around just-in-case
//
@if( $fh_gf_jqueryui_datepicker_enabled ) {

	// Local defaults from "Folkhack's Boilerplate"
	//	* Allows for stand-alone use
	//	* TODO: nix this ... I think I'm totally done f'n with jQuery UI
	$k_black:      #000000 !default;
	$k_white:      #FFFFFF !default;
	$k_mineshaft:  #303030 !default;
	$k_silver:     #CCCCCC !default;
	$k_whitesmoke: #F5F5F5 !default;

	.ui-datepicker-trigger {
		display: inline-block;
		margin-left: 5px;
	}
	.ui-widget {
		background: $k_mineshaft;
		box-shadow: 1px 4px 9px $k_silver;
	}
	.ui-datepicker {
		font-family: Verdana, sans-serif;
		font-size: 90% !important;
		padding: 0;
		a:hover { text-decoration: none; }
		.ui-datepicker-header {
			background: transparent;
			border: none;
			font-weight: normal;
			padding: 3px;
			a {
				color: $k_white;
				text-transform: uppercase;
			}
		}
		.ui-datepicker-header .ui-datepicker-title { text-align: center; }
		.ui-datepicker-month {
			margin-right: 5px;
		}
		.ui-datepicker-next {
			float: right;
			right: 2px;
		}
		.ui-datepicker-prev {
			float: left;
			left: 2px;
		}
		.ui-datepicker-next,
		.ui-datepicker-prev {
			cursor: pointer;
			top: 3px;
			span {
				font-size: 10px;
				font-weight: normal;
				margin-top: 0;
				position: relative;
				top: 0;
			}
		}
		table { margin: 0; }
		th {
			border-top: 1px solid $k_silver;
			border: none;
			color: $k_silver;
			font-size: 13px;
			font-weight: normal;
			padding: 3px 0;
			text-align: center;
		}
		td {
			background: $k_whitesmoke;
			border: none;
			color: $k_mineshaft;
			padding: 0;
			text-align: center;
		}
		td .ui-state-default {
			background: transparent;
			border: none;
			display: block;
			margin: 0;
			padding: .5em;
			text-align: center;
		}
		td .ui-state-active,
		td .ui-state-hover {
			background: $k_white;
		}
		.ui-state-disabled  { opacity: 1; }
	}

} @else {

	// Hide jQuery UI datepicker
	.ui-datepicker,
	.ui-datepicker-trigger { display: none !important; }
}

// Honeypot validation container
// - hides .gform_validation_container
.gform_validation_container {
  display: none;
}
